<template>
  <v-container fluid>
    <v-card tile>
      <v-toolbar>
        <v-btn icon :to="`/admin/${path}`" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>New reservation item</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form @submit.prevent="saveItem">
          <v-text-field v-model="item.name" label="Name"></v-text-field>
          <v-text-field v-model="item.roomNumber" label="Room number"></v-text-field>
          <v-text-field v-model="item.email" label="E-mail"></v-text-field>
          <v-text-field v-model="item.descriptionText" label="Description text"></v-text-field>
          <v-text-field type="number" v-model="item.maxConcurrency" label="Max concurrency"></v-text-field>
          <v-text-field type="number" v-model="item.maxDuration" label="Max duration"></v-text-field>
          <v-text-field v-model="item.availabilityStart" label="Availability start" prefix="Hour:" type="number"></v-text-field>
          <v-text-field v-model="item.availabilityEnd" label="Availability end" prefix="Hour:" type="number"></v-text-field>
          <v-btn type="submit" color="success">Save</v-btn>
          <v-btn text @click="$router.push(`/admin/${path}`)">Cancel</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      item: Object(),
      path: 'reservation_items'
    }
  },
  methods: {
    async saveItem () {
      await this.$store.dispatch('rest/createItem', {
        path: this.path,
        item: this.item
      })
      await this.$router.push(`/admin/${this.path}`)
    }
  },
  mounted () {
    document.title = 'New reservation item | Masarka Student Club'
  }
}
</script>
